<template>
  <v-app-bar
    height="85"
    min-height="85"
    color="#F2F0F0"
    fixed
    dense
    app
    style="box-shadow: none;"
  >
    <v-row class="head--toolbar">
      <v-col cols="8" sm="8" md="4" class="campaign--select">
        <v-select
          class="toolbarHeader"
          rounded
          :items="campaigns.slice()"
          item-text="name"
          item-value="id"
          filled
          background-color="#fff"
          @input="setSelected"
          id="id"
          hide-details
          v-model="activeCampaignFind"
        >
          <template v-slot:selection="{ item }" style="padding-top:0;">
            <div class="countAndName">
              <span class="caption">
                {{ campaigns.length }} Campaigns total</span
              >
              <br />
              <span>{{ item.name }}</span>
            </div>
          </template></v-select
        >
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="userActionsBtn">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div class="userControl" v-bind="attrs" v-on="on">
              <p>
                {{ getUserFullName === "" ? getUserEmail : getUserFullName }}
              </p>
              <v-avatar class="avatarUserActions">
                <v-icon large>
                  mdi-account-circle
                </v-icon>
                <v-icon medium color="green">
                  mdi-plus-circle
                </v-icon>
              </v-avatar>
            </div>
          </template>
          <v-list max-width="300">
            <v-list-item
              @click="
                setCampaigns();
                dialog = true;
              "
            >
              <v-list-item-title
                ><v-icon>mdi-account-plus</v-icon>Share</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title
                ><v-icon>mdi-exit-run</v-icon>Logout</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <share-dialog :DialogActive="dialog" :closeDialog="closeShareDialog" />
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import ShareDialog from "./common/ShareDialog.vue";

export default {
  name: "Toolbar",
  data: () => ({
    valid: false,
    forms: null,
    dialog: false,
    campaign: null,
    selected: null,
  }),
  components: {
    ShareDialog,
  },
  computed: {
    ...mapGetters(["getUserEmail", "campaigns", "defaultCampaign"]),
    ...mapState(["activeCampaign"]),
    activeCampaignFind: {
      get() {
        const idFromParams = this.$route.params.id;

        const foundOne = this.campaigns.find((campaign) => {
          if (idFromParams) {
            return campaign.id === idFromParams;
          }
          return campaign.id === this.activeCampaign || this.defaultCampaign;
        });
        return {
          id: foundOne.id,
          name: foundOne.name,
        };
      },
      set() {
        const idFromParams = this.$route.params.id;

        const foundOne = this.campaigns.find((campaign) => {
          if (idFromParams) {
            return campaign.id === idFromParams;
          }
          return campaign.id === this.activeCampaign || this.defaultCampaign;
        });
        this.selected = {
          id: foundOne.id,
          name: foundOne.name,
        };
      },
    },
    getUserFullName() {
      if (this.$store.state.user.fullName) {
        const name = this.$store.state.user.fullName;
        return name;
      }
      return "";
    },
  },
  created() {
    const idFromParams = this.$route.params.id;

    this.selected = this.campaigns.find((campaign) => {
      if (idFromParams) {
        return campaign.id === idFromParams;
      }
      return campaign.id === this.activeCampaign || this.defaultCampaign;
    });
    this.setActiveCampaign({
      name: idFromParams || this.activeCampaign || this.defaultCampaign,
    });
  },
  methods: {
    ...mapActions(["logout", "setError", "setActiveCampaign"]),
    changeCampaign(campaign) {
      this.setActiveCampaign({ name: campaign });
      if (this.$route.path != "/analytics") {
        if (this.$route.params.id !== campaign) {
          this.$router.push({ name: "", params: { id: campaign } });
        } else {
          this.setError("This campaign already selected.");
        }
      }
    },
    closeShareDialog() {
      this.dialog = !this.dialog;
    },
    setSelected(value) {
      this.changeCampaign(value);
    },
    async setCampaigns() {
      this.forms = this.$store.state.user.campaigns;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatarUserActions {
  position: relative;
  border-radius: 0;
  .mdi-plus-circle {
    position: absolute;
    top: 0;
    right: 0;
    height: 25px;
    width: 20px;
  }
}
.userControl {
  cursor: pointer;
  display: flex;
  flex-direction: reverse;
  align-items: center;
  p {
    margin-bottom: 0;
    color: #666666;
  }
}
.countAndName {
  position: absolute;
  left: 0;
  top: 10px;
}
.userActionsBtn {
  display: flex;
  justify-content: flex-end;
  height: 100%;
}
.head--toolbar {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
  height: 100%;
}

.campaign--select {
  max-width: 520px;
  height: 100%;
  .round {
    width: 100%;
    max-width: 520px;
  }
}
.email-container {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 500px) {
  .userControl {
    p {
      display: none;
    }
  }
}
</style>
