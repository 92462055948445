import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#fa98cb',
      },
    },
  },
  icons: {
    iconfont: "mdi"
  },
});

export default vuetify;
