import Vue from "vue";
import Router from "vue-router";
import store from "../store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/campaign/:id",
      name: "dashboard",
      component: () => import("../views/Dashboard.vue"),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          name: "profileView",
          path: "",
          component: () => import("../views/CampaignView.vue"),
        },
        {
          name: "campaignView",
          path: "campaignView",
          component: () => import("../views/ProfileView.vue"),
        },
        {
          name: "listView",
          path: "listView",
          component: () => import("../views/ListView.vue"),
        },
      ],
      redirect: {
        name: "parent.child",
      },
    },
    {
      path: "/calculator",
      name: "calculator",
      component: () => import("../views/Calculator"),
    },
    {
      path: "/",
      name: "login",
      component: () => import("../views/Login"),
    },
    {
      path: "/admin",
      name: "admin",
      component: () => import("../views/AdminPanel"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/analytics",
      name: "analytics",
      component: () => import("../views/Analytics"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/edit/:user",
      name: "editUser",
      component: () => import("../views/EditUser"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/passwordReset",
      name: "passwordResetView",
      component: () => import("../views/PWresetView"),
    },
    {
      path: "*",
      name: "notFound",
      component: () => import("../views/NotFound"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.user) {
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});

export default router;
