import axios from "axios";
import router from "../router";

export default {
  startAnimation({ commit }, type) {
    commit("START_ANIMATION", type);
  },
  setError({ commit }, msg) {
    commit("SET_ERROR", msg);
  },
  changeCandidatesTab({ commit }, candidateStatus) {
    commit("SET_CANDIDATES_SELECTED_TAB", candidateStatus);
  },
  async login({ commit }, user) {
    const { data } = await axios.post("/api/user/login", user, {
      withCredentials: true,
    });
    if (data.status === "OK") {
      const { user } = data;
      commit("LOGIN", user);
    } else {
      throw data.status;
    }
  },
  logout({ commit }) {
    commit("LOGOUT");
    router.push("/");
  },
  async fetchCandidates({ commit }, id) {
    try {
      commit("START_FETCH");
      const { data } = await axios.get(`/api/form/${id}`);
      data.forEach((c) => {
        c.submittedAt = Math.floor(
          (Date.parse(new Date()) - Date.parse(c.submittedAt)) / 86400000
        );
      });
      commit("SET_CANDIDATES", data);
      commit("STOP_FETCH");
    } catch (error) {
      if (error.response.status === 401) {
        commit("UNSET_USER");
        router.push("/");
      }
      commit("SET_ERROR", "Reload to application, failed to fetch");
    }
  },
  async fetchCandidates2({ commit }, id) {
    try {
      commit("START_FETCH");
      const { data } = await axios.get(`/api/form/${id}`);
      commit("SET_CANDIDATESEXCEL", data);
      commit("STOP_FETCH");
    } catch (error) {
      commit("SET_ERROR", "Reload to application, failed to fetch");
      if (error.response.status === 401) {
        commit("UNSET_USER");
        router.push("/");
      }
    }
  },
  async deleteCandidate({ commit }, id) {
    if (confirm("Are you sure?")) {
      await axios.delete(`/api/user/candidate/${id}`);
      const candidates = this.candidates.filter((o) => o._id !== id);
      commit("SET_CANDIDATES", candidates);
    }
  },
  async changeCandidateStatus({ commit }, data) {
    try {
      const randomActivateAnim = Math.floor(Math.random() * 4) + 1;
      await axios.put(`/api/form/${data.id}`, {
        filter: data.filter,
      });
      commit("CHANGE_CANDIDATE_STATUS", data);
      if (randomActivateAnim === 1 && data.filter === "accepted") {
        const randomNr = Math.floor(Math.random() * 3) + 1;
        commit("START_ANIMATION", randomNr);
      }
      if (randomActivateAnim === 1 && data.filter === "declined") {
        commit("START_ANIMATION", 5);
      }
      if (data.filter !== "Undecided") {
        const dataForAna = {
          formId: router.currentRoute.params.id,
          type: data.filter,
          date: new Date(),
        };
        await axios.post("/api/form/analytic", dataForAna);
      }
    } catch (error) {
      commit("SET_ERROR", "Failed to change candidate status");
    }
  },
  downloadXls({ commit, getters }, type) {
    const data = [];
    let allCandidates = null;
    if (type === "Accepted candidates") {
      allCandidates = getters.acceptedExcelCandidates;
    } else if (type === "Declined candidates") {
      allCandidates = getters.declinedExcelCandidates;
    } else if (type === "All candidates") {
      allCandidates = getters.allExcelCandidates;
    } else if (type === "Interviewed candidates") {
      allCandidates = getters.allExcelCandidates.filter(
        (candidate) => candidate.status === "interviewed"
      );
    } else {
      commit("SET_ERROR", "No list chosen");
    }
    const regex = new RegExp(`(${/[^a-zA-Z0-9 ]/g})|(${/[\wа-я]+/gi})`);
    allCandidates.reverse().forEach((c) => {
      const questions = JSON.parse(JSON.stringify(c.questions));
      const answers = JSON.parse(JSON.stringify(c.answers));

      const candidate = {
        Form: c.formName,
        Status: c.status === "Undecided" ? "new" : c.status,
        Date: c.submittedAt,
        Comment: c.comment || "",
        Rating: c.rating,
      };
      questions.forEach((q, i) => {
        candidate[q.questionTitle.replace(regex, "")] =
          answers.find((a) => a.questionId === q.questionId).answerText ||
          answers[i].answerText;
      });
      data.push(candidate);
    });
    commit("START_ANIMATION", 4);

    commit("SET_XLS_DATA", data);
  },
  async selfEmailSend({ commit, state }, data) {
    try {
      const mailOptions = {
        to: state.user.email,
        text: data.text,
        subject: data.subject,
        html: `<br>${data.subject} <br>👤${data.text.replace(
          /(?:\r\n|\r|\n)/g,
          "<br>"
        )}`,
      };
      await axios.post("/api/user/mail", mailOptions);
      commit("SET_ERROR", "Mail sent");
    } catch (error) {
      commit("SET_ERROR", `Failed to send yourself an email ${error}`);
    }
  },
  setActiveCampaign({ commit }, data) {
    const { name } = data;
    commit("SET_ACTIVE_CAMP", name);
  },
  async updateTemplate({ commit, state }, data) {
    try {
      let updateQuery = {};

      if (data.type === "interviewed") {
        updateQuery.interviewed = {
          interviewedSubject: data.subject,
          interviewedText: data.text,
        };
      }
      if (data.type === "declined") {
        updateQuery.declined = {
          declinedSubject: data.subject,
          declinedText: data.text,
        };
      }

      if (data.type === "accepted") {
        updateQuery.accepted = {
          acceptedSubject: data.subject,
          acceptedText: data.text,
        };
      }
      const res = await axios.post("/api/user/template/update", {
        email: state.user.email,
        ...updateQuery,
      });
      commit("TEMPLATE_UPT", { data: res.data.resData, type: data.type });
    } catch (error) {
      commit("SET_ERROR", `Template update failed: ${error}`);
    }
  },
  candidateNotifiedUpdate({ commit }, ids) {
    ids.forEach((id) => {
      commit("CANDIDATE_NOTIFIED", id);
    });
  },
};
