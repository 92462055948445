<template>
  <v-dialog
    v-model="DialogActive"
    @keydown.esc="closeDialog"
    persistent
    max-width="512"
    overlay-color="white"
    overlay-opacity="0.8"
    background-color="white"
  >
    <v-card class="mailSentSelectBox">
      <v-btn icon @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-title
        class="headline"
        style="padding-left: 27%; padding-bottom: 2%"
        >Share with colleague:</v-card-title
      >
      <v-form v-model="valid">
        <v-container>
          <v-row
            align="center"
            justify="space-around"
            style="width: 65%; margin-left: 18%"
          >
            <v-text-field v-model="name" label="Name" required></v-text-field>
          </v-row>
          <v-row
            align="center"
            justify="space-around"
            style="width: 65%; margin-left: 18%"
          >
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
          </v-row>
          <v-row
            align="center"
            style="width: 65%; margin-left: 18%; padding-bottom: 2%"
          >
            <v-select
              v-model="campaign"
              :items="forms"
              :rules="[(v) => !!v || 'Campaign is required']"
              label="Select campaign"
              item-text="name"
              item-value="id"
              return-object
              required
            ></v-select>
          </v-row>
          <v-spacer></v-spacer>
          <v-row style="padding-bottom: 5%; margin-right: 66%">
            <v-btn
              style="width: 100%; color: white"
              :disabled="!valid"
              color="#6353fa"
              class="mr-4"
              @click.prevent="createColleague()"
              >Share</v-btn
            >
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import generator from "generate-password";

export default {
  name: "ShareDialog",
  data: () => ({
    valid: false,
    forms: null,
    name: "",
    email: "",
    campaign: null,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
  }),
  props: {
    DialogActive: Boolean,
    closeDialog: Function,
  },
  created() {
    this.forms = this.$store.state.user.campaigns;
  },
  methods: {
    async sendMail(password) {
      try {
        const mailOptions = {
          to: this.email,
          text: `Someone has shared access to Your Level up recruitment campaign.<br> Your account details are: <br> Username: ${this.email} <br> Password: ${password} <br> Use this link to login: https://app.levelup.ee/`,
          subject: "Level up kampaania parool",
          html: `<br>Someone has shared access to Your Level up recruitment campaign.<br><br> Your account details are: <br>👤 Username: ${this.email} <br>🗝️ Password: ${password} <br>🔗 Use this link to login: https://app.levelup.ee/`,
        };
        await axios.post("/api/user/mail", mailOptions);
      } catch (error) {
        this.feedback = error;
      }
    },
    async createColleague() {
      try {
        const user = {
          email: this.email,
          password: generator.generate({ length: 10, numbers: true }),
          campaigns: [],
          fullName: this.name,
        };
        user.campaigns.push({
          id: this.campaign.id,
          name: this.campaign.name,
        });
        await axios.post("/api/user/create", user).then((response) => {
          if (response.data == "Campaign added") {
            this.DialogActive = false;
            this.email = "";
            this.campaign = null;
            this.DialogActive = false;
          } else if (response.data == "Colleague added") {
            this.sendMail(user.password);
            this.name = "";
            this.email = "";
            this.campaign = null;
            this.DialogActive = false;
          } else {
            alert("Error adding colleague");
          }
        });
      } catch (error) {
        this.feedback = error;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mailSentSelectBox {
  border: 1px solid black;
  background: #fff;
}
</style>
