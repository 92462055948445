<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :permanent="!$vuetify.breakpoint.smAndDown"
      app
      clipped
      style="top: 0; max-height: 100%;"
      width="84"
    >
      <div class="navBrand">
        <router-link :to="homeURL">
          <img src="@/assets/lilLogo.svg" />
        </router-link>
      </div>
      <v-list class="navMenu">
        <div v-if="loggedIn">
          <router-link :to="homeURL">
            <v-list-item link>
              <v-list-item-action>
                <v-icon>mdi-home</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Home</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <!-- <v-list-item
            link
            href="https://levelup.ee/uus-kampaania/"
            target="_blank"
          >
            <v-list-item-action>
              <v-icon>mdi-plus-box-multiple-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>New campaign</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <router-link to="/analytics">
            <v-list-item link>
              <v-list-item-action>
                <v-icon>mdi-poll</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Analytics</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link to="/admin" v-if="isUserAdmin">
            <v-list-item link>
              <v-list-item-action>
                <v-icon>mdi-shield-crown</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Admin panel</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>
        <router-link to="/" v-else>
          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-account-key</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Login</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
      </v-list>
      <template v-slot:append v-if="loggedIn">
        <v-list-item link @click.prevent="dialog = !dialog">
          <v-list-item-action>
            <v-icon>mdi-account-multiple-plus</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
    <share-dialog
      :DialogActive="dialog"
      :closeDialog="closeShareDialog"
    ></share-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ShareDialog from "./common/ShareDialog.vue";

export default {
  name: "Navbar",
  components: { ShareDialog },
  data() {
    return {
      drawer: true,
      dialog: false,
    };
  },
  computed: {
    ...mapGetters([
      "isUserAdmin",
      "loggedIn",
      "campaigns",
      "counter",
      "defaultCampaign",
    ]),
    currentRouteName() {
      return this.$route.name;
    },
    homeURL() {
      return `/campaign/${this.defaultCampaign}`;
    },
  },
  methods: {
    ...mapActions(["logout"]),
    closeShareDialog() {
      this.dialog = !this.dialog;
    },
    changeCampaign(campaign) {
      this.$router.push({ name: "dashboard", params: { id: campaign.id } });
    },
  },
};
</script>

<style scoped lang="scss">
.navMenu {
  p {
    font-size: 12px;
  }
  i {
    margin: 7px;
  }
}

.theme--light.v-navigation-drawer {
  background: #fff;
  i {
    color: #424242;
  }
}
.router-link-active {
  .v-list-item--link {
    background: linear-gradient(229deg, #604fe2 0%, #772276 100%);
    box-shadow: 0px 5px 15px #acb2c1;
    border-radius: 0px 5px 5px 0px !important;
    i {
      color: #fff;
    }
  }
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 90%;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 30%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: pre-wrap;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.switch {
  width: 100%;
  background-color: #424242;
}
#numberShow {
  background-color: #4f4f4f;
  border-radius: 30%;
}
a {
  text-decoration: none;
}
.navTitle {
  text-align: center;
  padding: 20px;
  h1 {
    font-weight: 400;
    color: white;
  }
}
.navBrand {
  color: white;
  display: flex;
  padding: 25px 15px;
  img {
    width: 50px;
    height: 50px;
    margin: 0 auto;
  }
  p {
    margin: auto 0;
  }
}

.v-list-item {
  height: 80px;
  i {
    font-size: 30px;
  }
}
.navMenu .v-list-item {
  margin: 10px 0;
}
@media only screen and (max-width: 600px) {
  .switch[data-v-2718b446] {
    position: relative;
  }
}
</style>
