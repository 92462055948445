import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import actions from "./actions";
import getters from "./getters";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: null,
    candidates: [],
    candidatesExcel: [],
    fetching: false,
    excelData: [],
    loading: true,
    candidatesSelectedTab: 0,
    successMessage: "",
    errorMsg: "",
    animation: false,
    animationType: 1,
    activeCampaign: "",
  },
  mutations: {
    TEMPLATE_UPT(state, tempData) {
      if (tempData.type === "accepted") {
        state.user.acceptedTemplate = tempData.data.acceptedTemplate;
      }
      if (tempData.type === "declined") {
        state.user.declinedTemplate = tempData.data.declinedTemplate;
      }
      if (tempData.type === "interviewed") {
        state.user.interviewedTemplate = tempData.data.interviewedTemplate;
      }
    },
    LOGIN(state, user) {
      state.user = user;
      if (state.user.campaigns.length) {
        state.activeCampaign = state.user.campaigns[0].id;
      }
    },
    CANDIDATE_NOTIFIED(state, id) {
      state = state.candidates.map((candidate) => {
        if (candidate._id === id) {
          candidate.notified = true;
          return candidate;
        }
        return candidate;
      });
    },
    LOGOUT(state) {
      state.user = null;
      state.activeCampaign = "";
    },
    FILTER_NOTIFIED_OUT(state, param) {
      console.log(param);
    },
    START_FETCH(state) {
      state.fetching = true;
    },
    STOP_FETCH(state) {
      state.fetching = false;
    },
    SET_CANDIDATES(state, candidates) {
      state.candidates = candidates;
    },
    SET_CANDIDATESEXCEL(state, candidatesExcel) {
      state.candidatesExcel = candidatesExcel;
    },
    CHANGE_CANDIDATE_STATUS(state, data) {
      state.candidates.find((x) => x._id === data.id).status = data.filter;
    },
    SET_XLS_DATA(state, data) {
      state.excelData = data;
    },
    SET_CANDIDATES_SELECTED_TAB(state, tab) {
      state.candidatesSelectedTab = tab;
    },
    SET_SUCCESS_MESSAGE(state, message) {
      state.successMessage = message;
    },
    SET_ERROR(state, data) {
      state.errorMsg = data;
    },
    UNSET_USER(state) {
      state.user = null;
    },
    START_ANIMATION(state, data) {
      state.animation = true;
      state.animationType = data;
    },
    SET_ACTIVE_CAMP(state, data) {
      state.activeCampaign = data;
    },
  },
  actions,
  getters,
  plugins: [createPersistedState()],
});

export default store;
