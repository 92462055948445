<template>
  <div>
    <span v-if="!$vuetify.breakpoint.smAndDown" class="bg"></span>
    <v-app>
      <v-content>
        <Toolbar v-if="loggedIn" />
        <v-container fluid>
          <v-layout text-center>
            <v-flex>
              <router-view :key="$route.fullPath" />
            </v-flex>
          </v-layout>
        </v-container>
      </v-content>
      <Navbar v-if="this.$route.name !== 'login'" />
    </v-app>
    <error-snackbar></error-snackbar>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Toolbar from "@/components/Toolbar";
import { mapState, mapGetters } from "vuex";
import ErrorSnackbar from "./components/ErrorSnackbar.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Toolbar,
    ErrorSnackbar,
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["loggedIn"]),
  },
};
</script>

<style>
.bg {
  background-attachment: fixed;
  background-size: 100% auto;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  transform: scale(1.1);
  background-image: linear-gradient(to bottom, transparent, #fafcfe),
    url("/img/background-levelup.a35223ee.png");
  background-repeat: no-repeat;
}
.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating)
  .v-navigation-drawer__border {
  background-color: transparent !important;
}
</style>
