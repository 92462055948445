import Vue from "vue";
import App from "./App.vue";
import JsonExcel from "vue-json-excel";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import VueConfetti from "vue-confetti";
import StarRating from "vue-star-rating";
import LottieAnimation from "lottie-web-vue";
import AudioVisual from "vue-audio-visual";

Vue.use(LottieAnimation); // add lottie-animation to your global scope
Vue.component("star-rating", StarRating);
Vue.component("downloadExcel", JsonExcel);
Vue.use(VueConfetti);
Vue.use(AudioVisual);

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
