export default {
  allTemplates(state) {
    return {
      accepted: state.user.acceptedTemplate,
      declined: state.user.declinedTemplate,
      interviewed: state.user.interviewedTemplate,
    };
  },
  allCountedCandidates(state) {
    const newCands = state.candidates.filter(
      (c) => c.status === "Undecided"
    ).length;
    const declined = state.candidates.filter(
      (c) => c.status === "declined"
    ).length;
    const accepted = state.candidates.filter(
      (c) => c.status === "accepted"
    ).length;
    const interviewed = state.candidates.filter(
      (c) => c.status === "interviewed"
    ).length;
    return {
      acceptedLength: accepted,
      interviewedlength: interviewed,
      declinedLength: declined,
      newLength: newCands,
    };
  },
  allCandidates(state) {
    return state.candidates;
  },
  lengthOfAllCandidates(state) {
    return state.candidates.length;
  },
  isUserAdmin(state) {
    return state.user == null ? false : state.user.isAdmin;
  },
  loggedIn(state) {
    return state.user != null;
  },
  campaigns(state) {
    return state.user == null ? [] : state.user.campaigns;
  },
  defaultCampaign(state) {
    if (state.activeCampaign) {
      return state.activeCampaign;
    }
    return state.user == null ? "" : state.user.campaigns[0].id;
  },
  counter(state) {
    return state.user == null ? [] : state.user.campaignsCount;
  },
  acceptedCounts(state) {
    return state.user == null ? [] : state.user.acceptedCounts;
  },
  ratingCounts(state) {
    return state.user == null ? [] : state.user.ratingCounts;
  },
  emailCounts(state) {
    return state.user == null ? [] : state.user.emailCounts;
  },
  emailTemplate(state) {
    return state.user == null ? [] : state.user.emailTemplate;
  },
  acceptedExcelCandidates(state) {
    return state.candidatesExcel.filter((c) => c.status === "accepted");
  },
  allAcceptedCandidates(state) {
    return state.candidates.filter((c) => c.status === "accepted");
  },
  acceptedCandidates(state) {
    const data = state.candidates
      .filter((c) => c.status === "accepted" && c.notified === false)
      .reverse();
    data.forEach((x) => {
      x["checkStatus"] = true;
    });
    return data;
  },
  acceptedNotifiedCandidates(state) {
    return state.candidates.filter(
      (c) => c.status === "accepted" && c.notified === true
    );
  },
  allExcelCandidates(state) {
    return state.candidatesExcel;
  },
  declinedExcelCandidates(state) {
    return state.candidatesExcel.filter((c) => c.status === "declined");
  },
  allDeclinedCandidates(state) {
    return state.candidates.filter((c) => c.status === "declined");
  },
  declinedCandidates(state) {
    const data = state.candidates
      .filter((c) => c.status === "declined" && c.notified === false)
      .reverse();
    data.forEach((x) => {
      x["checkStatus"] = true;
    });
    return data;
  },
  interviewedCandidatesMail(state) {
    const data = state.candidates
      .filter((c) => c.status === "interviewed" && c.notified === false)
      .reverse();
    data.forEach((x) => {
      x["checkStatus"] = true;
    });
    return data;
  },
  declinedNotifiedCandidates(state) {
    return state.candidates.filter(
      (c) => c.status === "declined" && c.notified === true
    );
  },
  undecidedCandidates(state) {
    return state.candidates.filter((c) => c.status === "Undecided").reverse();
  },
  GDPRcheck(state) {
    return state.user == null ? "" : state.user.GDPRterms;
  },
  getUserEmail(state) {
    return state.user.email;
  },
  candidatesStatusActiveTab(state) {
    return state.candidatesSelectedTab;
  },
  interviewedCandidates(state) {
    return state.candidates.filter((c) => c.status === "interviewed");
  },
  candidateTabs(state, getters) {
    return [
      {
        id: 1,
        title: "New Candidates",
        name: "new",
        total:
          getters.undecidedCandidates && getters.undecidedCandidates.length,
      },
      {
        id: 2,
        title: "Declined Candidates",
        name: "declined",
        total:
          getters.declinedCandidates && getters.allDeclinedCandidates.length,
      },
      {
        id: 3,
        title: "Accepted Candidates",
        name: "accepted",
        total:
          getters.acceptedCandidates && getters.allAcceptedCandidates.length,
      },
      {
        id: 4,
        title: "Interviewed",
        name: "interviewed",
        total:
          getters.interviewedCandidates && getters.interviewedCandidates.length,
      },
    ];
  },
  dataForProfileView(state, getters) {
    let data = [];
    if (state.candidatesSelectedTab === 0) {
      data = getters.undecidedCandidates;
    }
    if (state.candidatesSelectedTab === 1) {
      data = getters.allDeclinedCandidates;
    }
    if (state.candidatesSelectedTab === 2) {
      data = getters.allAcceptedCandidates;
    }
    if (state.candidatesSelectedTab === 3) {
      data = getters.interviewedCandidates;
    }
    data.sort(function (a, b) {
      return new Date(a.submittedAt) - new Date(b.submittedAt);
    });

    return data.sort((x) => (x.notified ? 1 : -1));
  },
  dataForListView(state, getters) {
    let headers = [];
    let content = [];
    let data = [];
    const afterHeaders = [
      { text: "Notified", value: "notified", with: "20px" },
      { text: "Submitted", value: "submitted", with: "20px" },
    ];
    if (state.candidatesSelectedTab === 0) data = getters.undecidedCandidates;
    if (state.candidatesSelectedTab === 1) data = getters.allDeclinedCandidates;
    if (state.candidatesSelectedTab === 2) data = getters.allAcceptedCandidates;
    if (state.candidatesSelectedTab === 3) data = getters.interviewedCandidates;
    headers = [
      { text: "", value: "actions" },
      { text: "Status", value: "status" },
      { text: "Rating", value: "rating" },
    ];
    data.map(
      ({ questions, answers, rating, status, notified, submittedAt, _id }) => {
        let info = {};
        info["id"] = _id;
        info["notStatusBool"] = notified;
        info["status"] = status;
        info["rating"] = rating;
        info["notified"] =
          notified === true ? "Mail sent!" : "Mail is not sent!";
        info["submitted"] =
          submittedAt === 1
            ? `${submittedAt} day ago`
            : `${submittedAt} days ago`;
        for (let i = 0; i < questions.length; i++) {
          headers = [
            ...headers,
            {
              text: questions[i].questionTitle,
              value: questions[i].questionTitle,
            },
          ];
          answers.forEach((z) => {
            const c = questions[i].questionTitle;
            if (questions[i].questionId === z.questionId) {
              if (
                z.answerText.includes("https://media.videoask.com/transcoded")
              ) {
                info[c] = z.answerText.split("?")[0];
              } else {
                info[c] = z.answerText;
              }
            }
          });
        }
        content.push(info);
      }
    );
    headers.push(...afterHeaders);

    headers = headers
      .filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.value === value.value && t.name === value.name
          )
      )
      .filter((q) => {
        return (
          q.text.indexOf(
            "*Olen tutvunud ja nõustun **[privaatsustingimustega](https://levelup.ee/privaatsuspoliitika/)**.*"
          ) === -1
        );
      });
    return { headers, content, data };
  },
};
