<template>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      tile
      color="error"
      icon="error"
    >
        <p>
            <v-icon color="#fff">mdi-alert-circle-outline</v-icon>
            {{ errorMsg }}
        </p>
        <template v-slot:action="{ attrs }">
            <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';
  export default {
    name: 'ErrorSnackbar',
    data: () => ({
      snackbar: false,
      timeout: 2000,
    }),
    computed: {
        ...mapState(['errorMsg'])
    },
     created() {
        this.unsubscribe = this.$store.subscribe((mutation) => {
          if (mutation.type === 'SET_ERROR') {
              this.snackbar = true;
          }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
  }
</script>